/* eslint-disable jsdoc/require-param -- ES Lint is complaining about the type params
 * as missing on the JS Doc comments for the constructor overloads, even though they're
 * not real parameters. */
import type {
  AbstractConstructor,
  Constructor,
  ConstructorParameters,
  InstanceTypeOf,
  MaybePromiseLike,
  Result,
  StringLiteral,
  Writeable
} from '@/type-utils';
import axios from 'axios';
import type { IHTTPErrorMetaData } from './api-utils/IHTTPErrorMetaData';
import { isConstructable } from './class-utils';

/**
 * Represents an object with a single property whose key is the name of
 * the new `Error` subclass and whose value is the constructor for that class.
 * If `CustomMetaData` is provided, it is mixed into the new Error type.
 */
type ErrorContainer<
  T,
  NewErrorType extends
    | Constructor<Error>
    | AbstractConstructor<Error>
    | ErrorConstructor,
  CustomMetaData extends IHTTPErrorMetaData | Record<string, unknown> = Record<
    string,
    never
  >
> =
  CustomMetaData extends Record<string, never>
    ? {
        [Key in StringLiteral<T>]: {
          new (
            ...args: ConstructorParameters<NewErrorType>
          ): InstanceTypeOf<NewErrorType>;
        };
      }
    : {
        [Key in StringLiteral<T>]: {
          new (
            ...args: ConstructorParameters<NewErrorType>
          ): InstanceTypeOf<NewErrorType> & CustomMetaData;
        };
      };

/**
 * Represents a stateless and immutable version of an `Error` instance. While `Error`s are
 * generally stateless to begin with, this type guarantees the ability to safely serialize
 * the error to a JSON string.
 */
export interface IErrorDTO {
  /** The name of the error type, for example, "Error" or "TypeError". */
  readonly name: string;

  /** The message associated with the error. */
  readonly message: string;

  /** The stack trace associated with the error. */
  readonly stack?: string;

  /** The cause of the error, if there is one. */
  readonly cause?: IErrorDTO;

  /**
   * A list of errors that relate to the wrapping error.
   * This is typically used for aggregate errors.
   */
  readonly errors?: Array<IErrorDTO>;
}

/**
 * Given an instance of an `Error` object, returns a JSON serializable object representing
 * the error and its cause, if it has one, recursively.
 * @param error - The error from which to generate the immutable DTO object.
 * @returns A JSON serializable object representing the error and its cause, if it has one.
 */
export const errorToDTO = (error: Error): IErrorDTO => {
  const errorObj = {
    name: error.name,
    message: error.message,
    stack: error.stack
  } as Writeable<IErrorDTO>;

  if (error.cause instanceof Error) {
    errorObj.cause = errorToDTO(error.cause as Error);
  }

  if (error instanceof AggregateError) {
    errorObj.errors = error.errors.map(errorToDTO);
  }

  return errorObj as IErrorDTO;
};

/**
 * Provides a formatted error message with optional stack trace information.
 * @param error - The from which to generate the message.
 * @param includeStackTrace - Whether to include the stack trace information in the message.
 * @param indentationLevel - The level by which the message should be indented.
 * @returns A formatted error message string with optional stack trace information.
 * @throws `Error` when the `error` argument is not an instance of `Error`.
 */
export const formatErrorMessage = (
  error: Error,
  includeStackTrace = false,
  useCompactStack = false,
  indentationLevel = 0
): string => {
  // If no error is provided or if the error is not an error object,
  // return an empty string - this can happen at runtime due to a bad cast.
  if (!error || !(error instanceof Error)) {
    throw new Error(
      `\`error\` must be an instance of \`Error\`. \`error\` was type of "${typeof error}"`
    );
  }

  // String to use for indentation.
  const indentationString = '    ';

  // The indentation string repeated by the indentation level.
  const indentation = indentationString.repeat(indentationLevel);

  // The indentation string repeated by the indentation level minus one, no less than zero.
  const previousIndentation = indentationString.repeat(
    Math.max(indentationLevel - 1, 0)
  );

  /** The error instance cast to a dictionary-like object. */
  const errorRecord = error as unknown as Record<string, string>;

  // Print the error message along with the stack trace.
  // Indents to the correct indentation level.
  return (
    previousIndentation +
    // Include the stack trace if `includeStackTrace` is true, or use just the message.
    (includeStackTrace
      ? // If the stack trace should be compact, use the compact stack trace instead of the original.
        useCompactStack && 'compactStack' in errorRecord
        ? errorRecord.compactStack
        : // Use either the original stack, if the error has a `_stack` property, or use `stack`.
          (errorRecord._stack || error.stack!).replace(/^/gm, indentation)
      : // Use either the original message, if the error has a `_message` property, or use `message`.
        errorRecord._message || error.message) +
    // print the inner error, if there is one.
    (error.cause instanceof Error
      ? '\n' +
        indentation +
        '  cause: \n' +
        formatErrorMessage(
          error.cause as Error,
          includeStackTrace,
          useCompactStack,
          indentationLevel + 1
        )
      : '')
  );
};

/** An interface representing all possible overloads of the Error Factory. */
interface IErrorFactory {
  /**
   * Creates a new `Error` subclass whose name corresponds to the string provided
   * for the `errorTypeName` parameter.
   * @param errorTypeName - The name of the new `Error` subclass.
   * @returns An object with a single property whose key is the name of
   * the new `Error` subclass and whose value is the constructor for that class.
   * @example ```ts
   * export const { ResourceNotFoundError } = errorFactory('ResourceNotFoundError');
   * export const { ProductNotFoundError } = errorFactory('ProductNotFoundError', ResourceNotFoundError);
   * ```
   */
  <
    T,
    ErrorInstance = Error,
    NewErrorType extends ErrorConstructor = ErrorConstructor
  >(
    errorTypeName: StringLiteral<T>
  ): ErrorContainer<T, NewErrorType>;

  /**
   * Creates a new `Error` subclass whose name corresponds to the string provided
   * for the `errorTypeName` parameter.
   * @param errorTypeName - The name of the new `Error` subclass.
   * @param extendsType - The `Error` subclass to extend. Defaults to `Error`.
   * @returns An object with a single property whose key is the name of
   * the new `Error` subclass and whose value is the constructor for that class.
   * @example ```ts
   * export const { ResourceNotFoundError } = errorFactory('ResourceNotFoundError');
   * export const { ProductNotFoundError } = errorFactory('ProductNotFoundError', ResourceNotFoundError);
   * ```
   */
  <
    T,
    ErrorInstance extends Error,
    NewErrorType extends
      | Constructor<ErrorInstance>
      | AbstractConstructor<ErrorInstance>
  >(
    errorTypeName: StringLiteral<T>,
    extendsType: NewErrorType
  ): ErrorContainer<T, NewErrorType>;

  /**
   * Creates a new `Error` subclass whose name corresponds to the string provided
   * for the `errorTypeName` parameter.
   * @param errorTypeName - The name of the new `Error` subclass.
   * @param customMetaData - An object representing any further properties to be
   * added to the error instance.
   * @returns An object with a single property whose key is the name of
   * the new `Error` subclass and whose value is the constructor for that class.
   * @example ```ts
   * export const { ResourceNotFoundError } = errorFactory('ResourceNotFoundError');
   * export const { ProductNotFoundError } = errorFactory('ProductNotFoundError', ResourceNotFoundError);
   * ```
   */
  <
    T,
    CustomMetaData extends Record<string, unknown>,
    ErrorInstance = Error,
    NewErrorType extends ErrorConstructor = ErrorConstructor
  >(
    errorTypeName: StringLiteral<T>,
    customMetaData: CustomMetaData
  ): ErrorContainer<T, NewErrorType, CustomMetaData>;

  /**
   * Creates a new `Error` subclass whose name corresponds to the string provided
   * for the `errorTypeName` parameter.
   * @param errorTypeName - The name of the new `Error` subclass.
   * @param extendsType - The `Error` subclass to extend. Defaults to `Error`.
   * @param customMetaData - An object representing any further properties to be
   * added to the error instance.
   * @returns An object with a single property whose key is the name of
   * the new `Error` subclass and whose value is the constructor for that class.
   * @example ```ts
   * export const { ResourceNotFoundError } = errorFactory('ResourceNotFoundError');
   * export const { ProductNotFoundError } = errorFactory('ProductNotFoundError', ResourceNotFoundError);
   * ```
   */
  <
    T,
    ErrorInstance extends Error,
    NewErrorType extends
      | Constructor<ErrorInstance>
      | AbstractConstructor<ErrorInstance>,
    CustomMetaData extends Record<string, unknown>
  >(
    errorTypeName: StringLiteral<T>,
    extendsType: NewErrorType,
    customMetaData: CustomMetaData
  ): ErrorContainer<T, NewErrorType, CustomMetaData>;
}

/**
 * Creates a new `Error` subclass whose name corresponds to the string provided
 * for the `errorTypeName` parameter.
 * @param errorTypeName - The name of the new `Error` subclass.
 * @param [extendsType] - The `Error` subclass to extend. Defaults to `Error`.
 * @param [customMetaData] - An object representing any further properties to be
 * added to the error instance.
 * @returns An object with a single property whose key is the name of
 * the new `Error` subclass and whose value is the constructor for that class.
 * @example ```ts
 * export const { ResourceNotFoundError } = errorFactory('ResourceNotFoundError');
 * export const { ProductNotFoundError } = errorFactory('ProductNotFoundError', ResourceNotFoundError);
 * ```
 */
export const errorFactory: IErrorFactory = <
  T,
  ErrorInstance extends Error,
  NewErrorType extends
    | Constructor<ErrorInstance>
    | AbstractConstructor<ErrorInstance>,
  CustomMetaData extends IHTTPErrorMetaData | Record<string, unknown> = Record<
    string,
    never
  >
>(
  errorTypeName: StringLiteral<T>,
  extendsType?: NewErrorType | CustomMetaData,
  customMetaData?: CustomMetaData
): ErrorContainer<T, NewErrorType, CustomMetaData> => {
  // Check if the second argument.
  if (!extendsType) {
    // If not, assign it a default value of the `Error` constructor.
    // eslint-disable-next-line no-param-reassign -- Assigning the default value.
    extendsType = Error as unknown as NewErrorType;
  }
  // Otherwise, we were provided a second argument. Check to see whether it is an
  // `Error` constructor, or an options object.
  else {
    // If the passed object is constructable, we know it's a constructor and not an
    // object literal value. So, if it's not constructable, we know it's an object
    // literal in this case and we'll assign the `Error` constructor to `extendsType`
    // as default and we'll re-assign the current value of the second arg to
    // `customMetaData`.
    // eslint-disable-next-line no-lonely-if -- Kept in this format purely for readability.
    if (!isConstructable(extendsType as Constructor<unknown>)) {
      // eslint-disable-next-line no-param-reassign -- Reassigning based on overload signature.
      customMetaData = extendsType as CustomMetaData;
      // eslint-disable-next-line no-param-reassign -- Reassigning based on overload signature.
      extendsType = Error as unknown as NewErrorType;
    }
  }

  /** A dynamic extension of Error or Error subclass.  */
  const NewErrorClass = class
    extends (extendsType as unknown as ErrorConstructor)
    implements Error
  {
    private _stack?: string;
    private _message?: string;

    /**
     * Outputs a more compact version of the stack trace. This is useful for logging,
     * particularly during builds where we may want to log the stack trace but not have it
     * consume too much space while providing enough useful information for debugging.
     */
    public get compactStack(): string {
      // Output the first 3 lines of the stack trace, along with a notice that the stack has been truncated.
      return (this._stack || this.stack!).replace(
        /((?:^.*? {4}at .*?\n){3})(?:(^.*? {4}at ).*?\n)?(?:^.*? {4}at .*?\n)*/gm,
        '$1$2... [Stack Truncated]\n'
      );
    }

    /** @inheritDoc */
    public constructor(...params: ConstructorParameters<typeof Error>) {
      super(...params);

      // If there is any custom data to associate with this error, spread it in.
      if (customMetaData) Object.assign(this, customMetaData);

      const [message, options] = params;

      // Makes the name of this exception the name of this class.
      this.name = this.constructor.name;

      // Set the cause in the event it would not be set otherwise.
      if (options?.cause) this.cause = options.cause;

      // Ensures the constructor is not included in the stack trace in V8.
      if (Error.captureStackTrace) {
        Error.captureStackTrace(this, this.constructor);
      }

      // Improve the message and stack strings to include the inner error.
      // Save the original stack.
      if (!this._stack) this._stack = this.stack!;
      // Get formatted stack trace, including cause.
      this.stack = formatErrorMessage(this, true);
      delete this._stack;

      // Get the passed message, if there was one.
      if (message) this._message = message;
      // Get the formatted message, including cause.
      this.message = formatErrorMessage(this, false);
      delete this._message;
    }

    /** @inheritDoc */
    public toString(): string {
      return this.stack!;
    }
  };

  // Set the name of this class to be whatever string was passed in for `errorTypeName`.
  Object.defineProperty(NewErrorClass, 'name', { value: errorTypeName });

  return {
    [errorTypeName]: NewErrorClass
  } as unknown as ErrorContainer<T, NewErrorType, CustomMetaData>;
};

/**
 * Asserts that error is from Error type.
 * @returns The Error.
 */
export const assertError = (error: unknown): Error => {
  if (error instanceof Error) return error;
  if (typeof error === 'string') return new Error(error);

  try {
    return new Error(JSON.stringify(error));
  } catch {
    // fallback in case there's an error stringifying the error
    // like with circular references for example.
    return new Error(String(error));
  }
};

/**
 * Checks if the error or any of its causes are of the specified error type (name).
 *
 * For aggregate errors, this will check all errors in the `errors` array as well.
 *
 * Note: This does **NOT** check the error's prototype chain, only the error's `cause` chain.
 * This is currently due to a technical limitation: Error DTOs are not deserialized into error'
 * instances, so we can't check the prototype chain.
 *
 * TODO(dennis): Implement a way to deserialize error DTOs into error instances.
 *
 * @param error - The error to check.
 * @param errorName - The name of the error type to check for.
 * @returns `true` if the error or any of its causes are of the specified error type
 * (name), or `false` otherwise.
 * @example
 * hasCause(new Error('foo'), 'Error'); // true
 * hasCause(new TypeError('foo'), 'Error'); // false
 * hasCause(new Error('foo'), 'TypeError'); // false
 * hasCause(
 *  new CannotMergeCartsError('foo', {
 *   cause: new RequestError('bar', {
 *     cause: new InvalidArgumentError('baz')
 *   })
 * }),
 * 'InvalidArgumentError'
 * ); // true
 */
export function hasCause(error: Error | IErrorDTO, errorName: string): boolean {
  let currentError = error as IErrorDTO | undefined;

  while (isIErrorDTO(currentError)) {
    const { name, errors } = currentError;

    if (name === errorName) return true;
    if (errors) {
      const result = errors.some((err) => hasCause(err, errorName));
      if (result) return true; // only return if found, otherwise continue to check the cause
    }

    currentError = currentError.cause;
  }

  return false;
}

/**
 * Checks if the given value satisfies the `IErrorDTO` interface.
 * @param error - The error to check.
 * @returns `true` if the value satisfies the `IErrorDTO` interface, or `false` otherwise.
 */
function isIErrorDTO(error: unknown): error is IErrorDTO {
  return (
    typeof error === 'object' &&
    error !== null &&
    'name' in error &&
    typeof (error as IErrorDTO).name === 'string'
  );
}

/**
 * Attempts to synchronously execute a function.
 *
 * **DO NOT** use this with asynchronous functions
 * as it will not catch errors thrown in promises.
 * Instead, use {@link tryCatchAsync}.
 *
 * @param fn - The function to execute synchronously.
 * @returns A tuple containing the return value of the function and `null` if the function
 * executed successfully, or `null` and the error that occurred if the function threw an error.
 * @example
 * const [result, error] = tryCatch(() => {
 *  // do something synchronously that might throw an error
 * });
 */
export function tryCatch<T>(fn: () => T): Result<T> {
  try {
    return [fn(), null];
  } catch (error) {
    return [null, assertError(error)];
  }
}

/**
 * Attempts to asynchronously execute a function.
 *
 * @param fn - The function to execute asynchronously.
 * @returns A `Promise` which resolves to a tuple containing
 * the return value of the function and `null` if the function
 * executed successfully, or `null` and the error that occurred
 * if the function threw an error.
 *
 * @example
 * const [result, error] = await tryCatchAsync(async () => {
 *  // do something that might throw an error
 * });
 */
export async function tryCatchAsync<T>(
  fn: () => MaybePromiseLike<T>
): Promise<Result<T>> {
  try {
    return [await fn(), null];
  } catch (error) {
    return [null, assertError(error)];
  }
}

/**
 * Extracts the error DTO from an Axios error.
 * @param error - The error to extract the DTO from.
 * @returns The error DTO if it exists, or `undefined` otherwise.
 */
export function extractErrorDTOFromAxios(error: Error): IErrorDTO | undefined {
  if (!axios.isAxiosError(error)) return undefined;
  return error.response?.data as IErrorDTO | undefined;
}
